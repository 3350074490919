
























































































import Component from 'vue-class-component';

import ReportsBanner from '@/components/reports/ReportsBanner.vue';
import ReportsGrid from '@/components/reports/ReportsGrid.vue';
import UiTabs2 from '@/components/ui/UiTabs2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

import { BaseVue } from '../../BaseVue';

type ReportsSection = { name: string; reports: string[] };

@Component({
  components: {
    UiTextEdit,
    UiTabs2,
    ReportsGrid,
    ReportsBanner,
  },
})
export default class ReportsNew extends BaseVue {
  public readonly tabs = [
    'Home',
    'Standard Reports',
    'Advanced Reports',
    'Inventory View Reports',
    'Audit Reports',
    'Treasury Reports',
    'Custom Reports',
  ];

  public readonly reports = [
    {
      id: 'balance-report',
      title: this.$tc('_balanceReport', 2),
      subtitle: 'Report on the balance of all accounts as of the end of a particular day.',
      to: '/reports/balanceReport',
    },
    {
      id: 'expanded-balance-report',
      title: 'Balance Report (Expanded)',
      subtitle: 'Expanded Report on the balance of all accounts as of the end of a particular day.',
      to: '/reports/expandedBalanceReport',
    },
    {
      id: 'expanded-gl-summary-report',
      title: 'General Ledger Summary Report',
      subtitle: 'A summary of the accounting transactions for a give period.',
      to: '/reports/expandedGeneralLedgerSummaryReport',
    },
    {
      id: 'expanded-val-rollforward-report',
      title: 'Valuation Rollforward Report (Expanded)',
      subtitle:
        'An Expanded Asset by Asset breakdown showing starting balances,  changes and ending balance for both quantity and value (cost basis/carrying value).',
      to: '/reports/expandedValuationRollforwardReport',
    },
    {
      id: 'expanded-pricing-summary-report',
      title: 'Pricing Summary Report',
      subtitle: 'A summary of the pricing status of all transactions.',
      to: '/reports/expandedPricingSummaryReport',
    },
    {
      id: 'transaction-actions-comparison-report',
      title: 'Transaction to Actions Comparison Report',
      subtitle:
        'A comparison of the transactions report to the actions report with differences indicating untracked actions by the tax engine.',
      to: '/reports/transactionActionsComparisonReport',
    },
    {
      id: 'xrp-custom-fusion-je-report',
      title: 'Oracle Fusion Journal Entry Report',
      subtitle: 'A customized Journal Entry report for Oracle Fusion',
      to: '/reports/xrpCustomFusionJournalEntryReport',
    },
    {
      id: 'xrp-custom-fusion-ecb-report',
      title: 'Oracle Fusion Expanded Cost Basis Report',
      subtitle: 'A customized Expanded Cost Basis report for Oracle Fusion',
      to: '/reports/xrpCustomFusionExpandedCostBasisReport',
    },
    {
      id: 'expanded-balance-recon-report',
      title: 'Balance Reconciliation Report',
      subtitle: 'A comparison of bitwave balance to third party balance for a specific period.',
      to: '/reports/expandedBalanceReconReport',
    },
    {
      id: 'transactions-export',
      title: this.$t('_exportTransactionReport'),
      subtitle: 'Export all transactions in CSV format.',
      to: '/reports/exports',
    },
    {
      id: 'transactions-export-lrt',
      title: this.$t('_exportTransactionReportLrt'),
      subtitle: 'Export all transactions in CSV format with ability to save the reports for future download.',
      to: '/reports/lrtTxnExport',
    },
    {
      id: 'transactions-export-datawarehouse',
      title: this.$t('_exportTransactionReport'),
      subtitle: 'Export all transactions in CSV format.',
      to: '/reports/exports?datawarehouse=true',
    },
    {
      id: 'journal-report',
      title: this.$tc('_journalReport', 2),
      subtitle: 'Export of expected expanded journal entries in target accounting system.',
      to: '/reports/journalReport',
    },
    {
      id: 'expanded-journal-report',
      title: this.$tc('_journalReportExpanded', 2),
      subtitle: 'Export of expected expanded journal entries in target accounting system.',
      to: '/reports/expandedJournalReport',
    },
    {
      id: 'rolled-up-journal-report',
      title: this.$tc('_rolledUpJournalReport', 2),
      subtitle: 'Export of expected journal entries in target accounting system grouped by contacts and categories.',
      to: '/reports/rolledUpJournalReport',
    },
    {
      id: 'ledger-report',
      title: this.$tc('_ledgerReport'),
      subtitle: 'Ledger view of transactions with running balance.',
      to: '/reports/ledgerReport',
    },
    {
      id: 'balance-check-report',
      title: this.$tc('_balanceCheckReport'),
      subtitle: 'Run balance compares from api data to Bitwave data.',
      to: '/reports/balanceCheckReport',
      // enabled: this.checkFeatureFlag('balance-check-enabled'), // How to gate from users in future if needed
    },
    {
      id: 'cost-basis-roll-forward',
      title: 'Valuation (Cost Basis) Rollforward',
      subtitle:
        'Asset by Asset breakdown showing starting balances,  changes and ending balance for both quantity and value (cost basis/carrying value).',
      to: '/inventory/views?view=reports&report=cost-basis-roll-forward',
    },
    {
      id: 'actions',
      title: 'Actions',
      subtitle:
        'A detailed transaction by transaction, lot by lot and action by action view of every transaction including quantities, cost basis, impairment and more.',
      to: '/inventory/views?view=reports&report=actions',
    },
    {
      id: 'reclass',
      title: 'Reclass',
      subtitle:
        'List of journals required to reclassify entries from a consolidated digital assets account to individualized asset accounts.',
      to: '/inventory/views?view=reports&report=je-reclass',
    },
    {
      id: 'actions-je-report',
      title: 'Actions Journal Entry Report',
      subtitle:
        'Journal entries constructed using the actions report. Report requires an error free inventory view to be selected.',
      to: '/inventory/views?view=reports&report=actions-je-report',
      isBeta: true,
    },
    {
      id: 'actions-tb-report',
      title: 'Actions Trial Balance Report',
      subtitle:
        'A trial balance constructed using the inventory view actions report. Report requires an error free inventory view to be selected.',
      to: '/inventory/views?view=reports&report=actions-tb-report',
    },
  ];

  public readonly homeTabSections: ReportsSection[] = [
    {
      name: 'Transaction & Balance',
      reports: [
        'balance-report',
        'expanded-balance-report',
        'transactions-export',
        'transactions-export-lrt',
        'transaction-actions-comparison-report',
        'balance-check-report',
        'expanded-pricing-summary-report',
        'expanded-balance-recon-report',
      ],
    },
    {
      name: 'Journals',
      reports: [
        'journal-report',
        'expanded-journal-report',
        'rolled-up-journal-report',
        'expanded-gl-summary-report',
        'reclass',
        'actions-je-report',
        'actions-tb-report',
      ],
    },
    {
      name: 'Valuation',
      reports: ['actions', 'cost-basis-roll-forward', 'expanded-val-rollforward-report'],
    },
    {
      name: 'Other',
      reports: ['ledger-report'],
    },
  ];

  public searchValue = '';

  private get searchedReports() {
    return this.reports.filter((report) => {
      if (!this.isReportEnabled(report.id)) {
        return false;
      }
      return (report.title as string).toLowerCase().includes(this.searchValue.toLowerCase());
    });
  }

  public isReportEnabled(reportId: string) {
    if (
      reportId === 'transactions-export-lrt' ||
      reportId === 'expanded-pricing-summary-report' ||
      reportId === 'expanded-balance-recon-report' ||
      reportId === 'transaction-actions-comparison-report' // ||
      // reportId === 'expanded-val-rollforward-report' ||
      // reportId === 'expanded-gl-summary-report'
    ) {
      return this.checkFeatureFlag('event-sourced-txns');
    }
    return true;
  }

  public getReportsOfSection(section: ReportsSection) {
    if (section.name === 'Other') {
      // Start with the base reports for the Other section
      const reportIds = [...section.reports];

      // Add custom reports if the feature flag is enabled
      if (this.checkFeatureFlag('xrp-custom-reports')) {
        reportIds.push('xrp-custom-fusion-ecb-report', 'xrp-custom-fusion-je-report');
      }

      // Return filtered reports
      return this.searchedReports.filter((report) => reportIds.includes(report.id));
    }
    return this.searchedReports.filter((report) => section.reports.includes(report.id));
  }

  public get standardReports() {
    return this.searchedReports.filter((report) =>
      [
        'balance-report',
        'transactions-export',
        'journal-report',
        'rolled-up-journal-report',
        'balance-check-report',
      ].includes(report.id)
    );
  }

  public get advancedReports() {
    return this.searchedReports.filter((report) =>
      [
        'expanded-balance-report',
        'expanded-journal-report',
        'transactions-export-datawarehouse',
        'transactions-export-lrt',
        'expanded-pricing-summary-report',
        'expanded-balance-recon-report',
        'transaction-actions-comparison-report',
        'expanded-val-rollforward-report',
        'expanded-gl-summary-report',
      ].includes(report.id)
    );
  }

  public get inventoryViewReports() {
    return this.searchedReports.filter((report) =>
      ['cost-basis-roll-forward', 'actions', 'reclass', 'actions-je-report', 'actions-tb-report'].includes(report.id)
    );
  }

  public get auditReports() {
    return this.searchedReports.filter((report) =>
      ['cost-basis-roll-forward', 'actions', 'balance-check-report'].includes(report.id)
    );
  }

  public get treasuryReports() {
    return this.searchedReports.filter((report) =>
      ['balance-report', 'expanded-balance-report', 'balance-check-report'].includes(report.id)
    );
  }

  public get customReports() {
    return this.searchedReports.filter((report) =>
      ['xrp-custom-fusion-ecb-report', 'xrp-custom-fusion-je-report'].includes(report.id)
    );
  }
}
